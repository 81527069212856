@import 'vars';

// generals
body {
  margin: 0;
  left: 0;
  top: 0;
  background: $white;
  overflow-y: overlay;
}

hr {
  border: 1px solid $light-grey;
}

// ::-webkit-scrollbar-track
// {
// 	// -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
// 	// background-color: #FFFFFF;
// 	background-color: #f2f2f2;
// }

// ::-webkit-scrollbar
// {
//   height: 12px;
//   // border-radius: 8px;
//   border-radius: 2px;
// }

// ::-webkit-scrollbar-thumb
// {
// 	// background-color: $salfa-blue-90;
// 	background-color: $salfa-blue-30;
//   border-radius: 0px;
// }

// -----------------------------------